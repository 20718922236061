import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PricingSection.css';


const PricingSection = () => {

  const [pricing, setPricing] = useState([]);


  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/pricings`)
      .then((res) => res.json())
      .then((info) => setPricing(info));
  }, []);

  return (


    <>

      <div id="pricing-sec" className="tp-price__area tp-price__border pt-120 pb-90  " >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-10">
              <div className="tp-price__section text-center pb-60 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">
                <h3 className="tp-section-title-sm pb-20">Pricing to suite all size of business</h3>
                <span>*We help companies of all sizes</span>
              </div>
            </div>
          </div>
          <div className="row g-0 align-items-end align-items-lg-center">

            {
              pricing.map(b => b.packageName === 'Basic' &&
                <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".5s">
                  <div className="tp-price__item">
                    <div className="tp-price__icon d-flex justify-content-between align-items-center">
                      <span className="icon price-color-1"><i className="fas fa-star" /></span>
                      <span>{b.packageName} Plan </span>
                    </div>
                    <h3 className="tp-price__title">$ {b.packagePrice}</h3>
                    <div className="tp-price__list">
                      <ul>

                        <li className="color-pink">{b.packagePointOne}</li>
                        <li className="color-pink">{b.packagePointTwo}</li>
                        <li className="color-pink">{b.packagePointThree}</li>
                        <li className="color-pink">{b.packagePointFour}</li>
                        <li className="color-pink">{b.packagePointFive}</li>

                      </ul>
                    </div>
                    <div className="tp-price__btn">

                      <Link to={`/order-now/${b._id}`} className="tp-btn-border">
                      <span>{b.packageButtonText}</span>  
                      </Link>
                    </div>
                  </div>
                </div>)}


            {
              pricing.map(s => s.packageName === 'Premium' &&
                <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".7s">
                  <div className="tp-price__item tp-price__active z-index">
                    <div className="tp-price__icon d-flex justify-content-between align-items-center">
                      <span className="icon price-color-2"><i className="fas fa-badge-check" /></span>
                      <span>{s.packageName} Plan</span>
                    </div>
                    <h3 className="tp-price__title">$ {s.packagePrice}</h3>
                    <div className="tp-price__list">
                      <ul>
                        
                        <li className="color-sky">{s.packagePointOne}</li>
                        <li className="color-sky">{s.packagePointTwo}</li>
                        <li className="color-sky">{s.packagePointThree}</li>
                        <li className="color-sky">{s.packagePointFour}</li>
                        <li className="color-sky">{s.packagePointFive}</li>
                        <li className="color-sky">{s.packagePointSix}</li>
                        <li className="color-sky">{s.packagePointSeven}</li>
                       
                      </ul>
                    </div>
                    <div className="tp-price__btn">
                    <Link to={`/order-now/${s._id}`} className="tp-btn-border">
                    <span> {s.packageButtonText}</span>
                      </Link>
                    </div>
                  </div>
                </div>)}


                {
              pricing.map(p => p.packageName === 'Standard' &&  
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".5s">
              <div className="tp-price__item">
                <div className="tp-price__icon d-flex justify-content-between align-items-center">
                  <span className="icon price-color-3"><i className="fas fa-cog" /></span>
                  <span>{p.packageName} Plan </span>
                </div>
                <h3 className="tp-price__title">$ {p.packagePrice}</h3>
                <div className="tp-price__list">
                  <ul>
                    <li>{p.packagePointOne}</li>
                    <li>{p.packagePointTwo}</li>
                    <li>{p.packagePointThree}</li>
                    <li>{p.packagePointFour}</li>
                    <li>{p.packagePointFive}</li>
                    
                  </ul>
                </div>
                <div className="tp-price__btn">
                  
                  <Link to={`/order-now/${p._id}`} className="tp-btn-border">
                  <span>{p.packageButtonText}</span>
                      </Link>
                </div>
              </div>
            </div>)}




           
          </div>
        </div>
      </div>

    </>
  );
};

export default PricingSection;