import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBottom from '../components/HomePage/HeaderBottom';


const ContactUs = () => {

  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);





  const handleMessage = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    const number = event.target.number.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;


    const addItem = {
      name,
      email,
      number,
      subject,
      message,
      messageStatus,

    };

    const url = `https://digitalone-13933e923da3.herokuapp.com/add-message`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Message is Send. Thanks');
      });
  };

  const [action, setAction] = useState([]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);



  return (




    <>
    <div className="tp-conatact-area pt-125 pb-125" id='contact-sec'>
  <div className="container">
    {
      footerAddress.map(a=> <div className="row">
      <div className="col-xl-4 col-lg-4 col-md-4 mb-30">
        <div className="contact-info text-center">
          <span className="contact-icon"><i className="fas fa-map-marker-alt" /></span>
          <h4>Visit Us Daily</h4>
          <span>
            <a href="https://www.google.com.bd/maps/@23.74591,90.3701368,15z" target="_blank">{a.location}</a>
          </span>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4 mb-30">
        <div className="contact-info text-center">
          <span className="contact-icon"><i className="fas fa-phone-volume" /></span>
          <h4>Contact Us</h4>
          <span>
            <a href="/">{a.phoneNumber} <br />
              </a>
          </span>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4 mb-30">
        <div className="contact-info text-center">
          <span className="contact-icon"><i className="fas fa-envelope" /></span>
          <h4>Email Us</h4>
          <span><a href="/">{a.emailAddress}</a><br />
            <a href="mailto:Webyourinfo@gmail.com"></a></span>
        </div>
      </div>
    </div>)
    }
    
    <div className="row">
      <div className="col-xl-12">
        <div id="contact" className="contact-form-box pt-60">
          <div className="contact-form-box text-center">
            <div className="row justify-content-center">
              <div className="col-8">
                <h4 className="contact-title">Send us a Message :</h4>
              </div>
            </div>
            <form onSubmit={handleMessage} action="#">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="tp-contact-input">
                    <input type="text" name='name' placeholder="Enter your Name" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="tp-contact-input">
                    <input type="email" name='email' placeholder="Enter your Email" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="tp-contact-input">
                    <input type="text" name='number' placeholder="Enter your Number" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="tp-contact-input">
                    <input type="text" name='subject' placeholder="Subject" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="tp-contact-input">
                  <input  name="messageStatus" value="UnRead" hidden placeholder="value" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="tp-contact-input">
                    <textarea placeholder="Type your Message" name='message' defaultValue={""} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="tp-submit-button">
                    <button type="submit" className="tp-btn-blue-square"><span>Send Message</span></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </>
  );
};

export default ContactUs;