import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Banner.css';

const Banner = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/banner`)
      .then((res) => res.json())
      .then((info) => setBanners(info));
  }, []);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subscriberEmail = event.target.subscriberEmail.value;

    const sunscribe = {
      subscriberEmail

    };

    const url = `https://digitalone-13933e923da3.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(sunscribe),
    })
      .then((res) => res.json())
      .then((result) => {

        alert('Thanks For Subscribe..');
      });
  };



  return (



    <>

    {
      banners.map(b=> <div className="tp-hero__area pt-100 pb-80 p-relative">
      <div className="tp-hero__shape-3-1 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".7s">
        <img src="assets/img/hero/hero-shape-3-3.png" alt />
      </div>
      <div className="tp-hero__shape-3-2 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
        <img src="assets/img/hero/hero-shape-3-4.png" alt />
      </div>
      <div className="container">
        <div className="row align-items-start">
          <div className="col-xl-6 col-lg-6">
            <div className="tp-hero__content-box">
              <h3 className="tp-hero__title-lg pb-20 theme-color wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">{b.bannerHeading}
              </h3>
              <p className="wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".5s">{b.bannerText}</p>
            </div>
            <div className="tp-hero__button-box wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".7s">
              <a className="tp-btn-blue-square mb-20 mr-25" href={b.buttonURL}><span>{b.buttonText}</span></a>
             
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".9s">
            <div className="tp-hero__wrapper-3 d-flex justify-content-center justify-content-lg-end">
              <div className="tp-hero__thumb-3">
                <img src={b.imageOne} alt />
                <div className="tp-hero__thumb-shape-1">
                  <img src="assets/img/hero/hero-shape-3-1.png" alt />
                </div>
                <div className="tp-hero__thumb-shape-2">
                  <img src="assets/img/hero/hero-shape-3-2.png" alt />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
    }











    </>
  );
};

export default Banner;
