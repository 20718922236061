import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";


const AboutSection = () => {

  const [about, setAbout] = useState([]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/about`)
      .then((res) => res.json())
      .then((info) => setAbout(info));
  }, []);





  return (



    <>
    {
      about.map(a=><div className="tp-about__area pt-120 pb-120 p-relative" id="about-sec">
      <div className="tp-about__shape-3-1">
        <img src="assets/img/hero/hero-shape-3-5.png" alt />
      </div>
      <div className="tp-about__shape-3-2">
        <img src="assets/img/hero/hero-shape-3-6.png" alt />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-6 col-lg-6 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".5s">
            <div className="tp-about__thumb-wrapper p-relative">
              <div className="tp-about__shape-1">
                <img src="assets/img/hero/hero-shape-3-1.png" alt />
              </div>
              <div className="tp-about__shape-2">
                <img src="assets/img/hero/hero-shape-3-2.png" alt />
              </div>
              <div className="tp-about__thumb">
                <img src={a.aboutImgOne} alt />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 wow tpfadeRight" data-wow-duration=".9s" data-wow-delay=".7s">
            <div className="tp-about__section-box">
              <h3 className="tp-section-title-md pb-30">{a.aboutHeading}</h3>
              <div className="tp-about__text">
                <p>{a.aboutPointOne}</p>
                <p>{a.aboutPointTwo}</p>
              </div>
              <a className="tp-btn-blue-square" href={a.aboutButtonLink}><span>{a.aboutButtonText}</span></a>
            </div>
          </div>
        </div>
      </div>
    </div> )
    }
   













    </>
  );
};

export default AboutSection;
