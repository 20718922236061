// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA32hHq3fmc5IYQz0bcP1dOIYUbK5cUsM8",
  authDomain: "digitalmarketingassetsone.firebaseapp.com",
  projectId: "digitalmarketingassetsone",
  storageBucket: "digitalmarketingassetsone.appspot.com",
  messagingSenderId: "131160281554",
  appId: "1:131160281554:web:fe0318e73a97257dce380b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;