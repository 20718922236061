import React, { useEffect, useState } from "react";

const TestimonialSection = () => {
  const [testimonial, setTestimonial] = useState([]);

  const [testimonialtext, setTestimonialText] = useState([]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/testimonialtext`)
      .then((res) => res.json())
      .then((info) => setTestimonialText(info));
  }, []);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/testimonials`)
      .then((res) => res.json())
      .then((info) => setTestimonial(info));
  }, []);


  const divStyle = {
    backgroundImage: "url(img/testimonial/test-bg-aliments.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#fff7f5",
  };

  return (

    <>

<div className="tp-testimonial__area grey-bg pt-120 pb-120">
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="tp-testimonial__title-box text-center mb-80 wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".3s">
          <h3 className="tp-section-title">User Feedback 🎖️</h3>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-xl-6 col-lg-6 col-12 wow tpfadeLeft" data-wow-duration=".9s" data-wow-delay=".5s">
        <div className="tp-testimonial__thumb">
          <img src="https://i.ibb.co/30RhRNF/Untitled-design-2024-04-10-T083408-016.png" alt />
        </div>
      </div>
      <div className="col-xl-6 col-lg-6 col-12">
        <div className="tp-testimonial__wrapper">
          {
            testimonial.map(t=> <div className="tp-testimonial__item mb-15 d-flex wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".7s">
            <div className="tp-testimonial__icon">
              <span><img src={t.image} alt /></span>
            </div>
            <div className="tp-testimonial__content">
              <p>{t.desc}</p>
              <h3 className="tp-testimonial__title-sm">{t.personName} <span>({t.personTitle})</span></h3>
            </div>
          </div>)
          }

          

        </div>
      </div>
    </div>
  </div>
</div>







    </>



  );
};

export default TestimonialSection;
