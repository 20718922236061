import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";

const Footer = () => {



  const [footerSocial, setFooterSocial] = useState([]);
  const [footerCopyright, setFooterCopyright] = useState([]);

  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);




  const [footerAddress, setFooterAddress] = useState([]);


  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, [footerAddress]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/footer-social`)
      .then((res) => res.json())
      .then((info) => setFooterSocial(info));
  }, []);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/copyrights`)
      .then((res) => res.json())
      .then((info) => setFooterCopyright(info));
  }, [footerCopyright]);



  const handleSubscriber = (event) => {
    event.preventDefault();
    const subemail = event.target.subemail.value;




    const addItem = {
      subemail,



    };

    const url = `https://digitalone-13933e923da3.herokuapp.com/add-subscriber`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(addItem),
    })
      .then((res) => res.json())
      .then((result) => {
        
        alert('Subscribers is Updated');
      });


  }





  return (


    <>

    {
      footerSocial.map(c=> <footer>

  
        {/* tp-footer-area-start */}
        <div className="tp-footer__area theme-bg pt-120 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="tp-footer__content text-center">
                  <h3 className="tp-section-title text-white wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".3s">About Us</h3>
                  <p className="wow tpfadeUp " data-wow-duration=".9s" data-wow-delay=".5s">{c.aboutText}</p>
                </div>
                <div className="tp-footer__thumb d-flex justify-content-center wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".7s">
                  <div className="tp-footer__thumb-sm">
                    
                  </div>
                  <div className="tp-footer__thumb-sm">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tp-footer-bottom__area mt-80 wow tpfadeUp" data-wow-duration=".9s" data-wow-delay=".9s">
            <div className="container">
              <div className="tp-footer-bottom__border-top pt-40">
                <div className="row align-items-center">
                  <div className="col-xl-2 col-lg-2 col-md-6 col-12 order-2 order-lg-1 text-center text-md-start">
                    {
                      logo.map(l=> <div className="tp-footer-bottom__logo">
                      <a href="/"><img src={l.logo} alt /></a>
                    </div>)
                    }
                    
                  </div>
                  <div className="col-xl-7 col-lg-7 col-md-12 col-12 order-1 order-lg-2 d-none d-sm-block">
                    <div className="tp-footer-bottom__menu">
                      <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="#about-sec">About</a></li>
                        <li><a href="#pricing-sec">Pricing</a></li>
                        <li><a href="#services-sec">Services</a></li>
                        <li><a href="#contact-sec">Contact</a></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-12 order-2 order-lg-3 text-center text-md-end">
                    <div className="tp-footer-bottom__social">
                      <ul>
                        <li><a href={c.fblink}><i className="fab fa-facebook-f" /></a></li>
                        <li><a href={c.youlink}><i className="fab fa-youtube" /></a></li>
                        <li><a href={c.inslink}><i className="fab fa-instagram" /></a></li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tp-footer-area-end */}
      </footer>)
    }





      






    </>
  );
};

export default Footer;
