import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";



const NavBar = () => {

  const [user] = useAuthState(auth);
  const handleSignOut = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);


  useEffect(() => {
    fetch(`https://digitalone-13933e923da3.herokuapp.com/users`)
      .then((res) => res.json())
      .then((info) => setUser(info));
  }, []);




  return (


    // <>
    // {
    //   logo.map(l=>  <div className="header__area header__sticky">
    //   <div className="container custom__container">
    //     <div className="header__area-menubar">
    //       <div className="header__area-menubar-left">
    //         <div className="header__area-menubar-left-logo">
    //           <a href="/"><img className="dark-n" src={l.logo} alt /><img className="light-n" src={l.logo} alt /></a>
    //         </div>
    //       </div>
    //       <div className="header__area-menubar-center">
    //         <div className="header__area-menubar-center-menu menu-responsive">
    //           <ul id="mobilemenu">
    //             <li ><a href="/">Home</a></li>
    //             <li ><a href="#about-sec">About Us</a></li>
    //             <li ><a href="#services-sec">Services</a></li>
    //             <li ><a href="#pricing-sec">Pricing</a></li>
    //             <li ><a href="#contact-sec">Contact</a></li>





                // {
                //   user?.email ?

                //     <Link to="/dashboard" className="tp-btn-blue-square m-2 "   >Dashboard</Link>

                //     :

                //     <Link to="/login" className="tp-btn-blue-square" >Log in</Link>

                // }


                // {
                //   users.map(u => u.userEmail === user?.email && u.userRole === 'Admin' &&

                //     <Link to="/admin" className="tp-btn-blue-square"  >Admin</Link>

                //   )
                // }
    //           </ul>
    //         </div>
    //       </div>
    //       <div className="header__area-menubar-right">
    //         <div className="header__area-menubar-right-box">


    //           {/* sidebar Menu Start */}
              
    //           <div className="sidebar-overlay" />
    //           {/* sidebar Menu Start */}
    //         </div>
    //         <div className="responsive-menu" />
    //       </div>
    //     </div>
    //   </div>
    // </div> )
    // }

     



    // </>

    <>

    
    

<header>
  {/* tp-header-area-start */}
  <div className="tp-header__top-area theme-bg tp-header__space-3 d-none d-sm-block">
    
  </div>
  <div id="header-sticky" className="tp-header__area tp-header__space-3 tp-header__bottom tp-header__menu-space p-relative">
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="col-xl-2 col-lg-2 col-md-6 col-7">
          {
            logo.map(l=> <div className="tp-header__logo">
            <a href="/">
              <img src={l.logo} alt />
            </a>
          </div>)
          }
          
        </div>
        <div className="col-xl-7 col-lg-7 d-none d-lg-block z-index-5">
          <div className="tp-header__main-menu tp-header__black-menu tp-header__menu-3">
            <nav id="mobile-menu">
              <ul>
                
                <li><a href="/">Home</a></li>
                <li><a href="#services-sec">Services</a></li>
                <li><a href="#about-sec">About</a></li>
                <li><a href="#pricing-sec">Pricing</a></li>
                <li><a href="#contact-sec">Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-6 col-5">
          <div className="tp-header__right-two d-flex align-items-center justify-content-end">
            {
                  user?.email ?

                    <Link to="/dashboard" className="tp-btn-blue-square"   > <span>Dashboard</span></Link>

                    :

                    <Link to="/login" className="tp-btn-blue-square" >Log in</Link>

                }


                {
                  users.map(u => u.userEmail === user?.email && u.userRole === 'Admin' &&

                    <Link to="/admin" className="tp-btn-blue-square"  ><span>Admin</span></Link>

                  )
                }
            <a className="tp-header__bars tp-menu-bar d-lg-none" href="javascript:void(0)"><i className="far fa-bars" /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* tp-header-area-end */}
</header>

<div>
  {/* tp-offcanvus-area-start */}
  <div className="tp-offcanvas-area">
    <div className="tpoffcanvas">
      <div className="tpoffcanvas__close-btn">
        <button className="close-btn"><i className="fal fa-times" /></button>
      </div>
      <div className="tpoffcanvas__logo">
        <a href="index.html">
          <img src="assets/img/logo/logo.png" alt />
        </a>
      </div>
      <div className="tpoffcanvas__text">
        <p>Suspendisse interdum consectetur libero id. Fermentum leo vel orci porta non. Euismod viverra nibh cras
          pulvinar suspen.</p>
      </div>
      <div className="mobile-menu" />
      <div className="tpoffcanvas__info">
        <h3 className="offcanva-title">Get In Touch</h3>
        <div className="tp-info-wrapper mb-20 d-flex align-items-center">
          <div className="tpoffcanvas__info-icon">
            <a href="#"><i className="fal fa-envelope" /></a>
          </div>
          <div className="tpoffcanvas__info-address">
            <span>Email</span>
            <a href="maito:hello@yourmail.com">hello@yourmail.com</a>
          </div>
        </div>
        <div className="tp-info-wrapper mb-20 d-flex align-items-center">
          <div className="tpoffcanvas__info-icon">
            <a href="#"><i className="fal fa-phone-alt" /></a>
          </div>
          <div className="tpoffcanvas__info-address">
            <span>Phone</span>
            <a href="tel:(00)45611227890">(00) 456 1122 7890</a>
          </div>
        </div>
        <div className="tp-info-wrapper mb-20 d-flex align-items-center">
          <div className="tpoffcanvas__info-icon">
            <a href="#"><i className="fas fa-map-marker-alt" /></a>
          </div>
          <div className="tpoffcanvas__info-address">
            <span>Location</span>
            <a href="https://www.google.com/maps/@37.4801311,22.8928877,3z" target="_blank">Riverside 255, San
              Francisco, USA </a>
          </div>
        </div>
      </div>
      <div className="tpoffcanvas__social">
        <div className="social-icon">
          <a href="#"><i className="fab fa-twitter" /></a>
          <a href="#"><i className="fab fa-instagram" /></a>
          <a href="#"><i className="fab fa-facebook-square" /></a>
          <a href="#"><i className="fab fa-dribbble" /></a>
        </div>
      </div>
    </div>
  </div>
  <div className="body-overlay" />
  {/* tp-offcanvus-area-end */}
</div>


    
    </>
  );
};

export default NavBar;
